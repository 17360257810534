// frontend/src/components/ZapFlow.js
import React, { useState } from 'react';
import Zap from './Zap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function ZapFlow() {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Zap />;
      case 2:
        return <div>Review your workflow</div>;
      case 3:
        return <div>Save or execute your workflow</div>;
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Container>
      <Row className='justify-content-md-center my-5 pt-5'>
        <Col md='8'>
          <input type='text' placeholder='    Workflow' />
        </Col>
      </Row>

      <Row className='justify-content-md-center'>
        <Col md='8'>
          <Card>
            <Card.Body className='p-0'>
              {renderStep()}
              <div className='d-flex justify-content-between mt-3'>
                {currentStep > 1 && (
                  <Button variant='secondary' onClick={prevStep}>
                    Previous
                  </Button>
                )}
                {currentStep < 3 && (
                  <Button variant='primary' onClick={nextStep}>
                    Next
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default ZapFlow;
