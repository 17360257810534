import React from 'react'
import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'

const HomeNav = () => {
  return (
    <Navbar bg='light' expand='lg' className='fixed-top'>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link as={NavLink} to='/workflow-editor' activeClassName='active'>
              Workflow Editor
            </Nav.Link>
            <Nav.Link as={NavLink} to='/display-data' activeClassName='active'>
              View Flows
            </Nav.Link>
            <Nav.Link as={NavLink} to='/zap-flow' activeClassName='active'>
              Zap Remake
            </Nav.Link>
            <Nav.Link as={NavLink} to='/dynamic-form' activeClassName='active'>
              Trigger Form
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HomeNav
