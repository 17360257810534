import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Button, Col, Row } from 'react-bootstrap';
import WorkflowStep from './WorkflowStep';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import { set } from 'date-fns';

const initialSteps = [{ id: 1, type: 'trigger', triggerType: '', triggerTypeName: '', name: '', description: '' }];

const WorkflowEditor = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [steps, setSteps] = useState(initialSteps);
  const [title, setTitle] = useState('');
  const [autoSaved, setAutoSaved] = useState(false);

  const isMounting = useRef(true);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const isTriggerConfigured = () => {
    const triggerStep = steps[0];
    return triggerStep && triggerStep.triggerType && triggerStep.triggerTypeName;
  };

  const saveData = async () => {
    const isNewWorkflow = !id;
    let newId = id || uuidv4();
    let newTitle = title || 'Untitled' + new Date().toLocaleString();
    const method = isNewWorkflow ? 'POST' : 'PUT';
    const created = isNewWorkflow ? new Date().toISOString() : null;
    const updated = new Date().toISOString();
    const response = await fetch('/saveData.php', {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      // Use the pre-generated newId here
      body: JSON.stringify({ id: newId, title: newTitle, steps, created, updated }),
    });

    if (response.ok) {
      const result = await response.json();
      console.log(result); // Process success response

      if (!id) {
        navigate(`/workflow/${newId}`, { replace: true });
        console.log('result', result);
        console.log('newId', newId);
      }
    } else {
      console.error('Failed to save data');
    }
  };

  const autosave = () => {
    const debouncedSave = debounce(() => {
      // Ensure component is not just mounting and required conditions are met
      if (!isMounting.current && (title || steps.some(step => step.triggerType || step.description || step.name))) {
        saveData();
      }
    }, 2000);
    debouncedSave();
  }

  // Effect for autosave, watches for changes in steps and title
  useEffect(() => {
    // Skip autosave on the initial render
    if (isMounting.current) {
      isMounting.current = false;
    } else {
      autosave();
    }
  }, [steps, title]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await fetch(`/getFlow.php?id=${id}`);
          if (response.ok) {
            const data = await response.json();
            setTitle(data.title);
            setSteps(data.steps || []);
            setAutoSaved(true); // Indicate that the workflow has been loaded.
          } else {
            console.error('Failed to fetch workflow data');
          }
        } catch (error) {
          console.error('Error fetching workflow data:', error);
        }
      } else {
        setSteps(initialSteps);
        setTitle('');
        setAutoSaved(false);
        isMounting.current = true;
      }
    };

    fetchData();
  }, [id]);

  const addStep = useCallback((isTrigger = false) => {
    const newStep = {
      id: uuidv4(),
      type: 'action',
      triggerType: '',
      triggerTypeName: '',
      name: '',
      description: '',
    };
    setSteps(prevSteps => [...prevSteps, newStep]);
  }, []);

  const updateStep = (id, updatedFields) => {
    setSteps(steps.map(step => (step.id === id ? { ...step, ...updatedFields } : step)));
    console.log('id', id, 'updatedFields', updatedFields);
  };

  const deleteStep = id => {
    const confirmDelete = window.confirm('Press Ok to delete this poor, defenseless little step forever.');
    if (confirmDelete) {
      setSteps(steps.filter(step => step.id !== id));
    }
  };

  return (
    <>
      <Container>
        <Row className='d-flex flex-row justify-content-between mt-3 pt-5'>
          <Col lg={6} className='mt-5 mb-3 mx-auto'>
            <div className='form-floating'>
              <input type='text' className='form-control h1' id='workflowName' placeholder='Enter workflow name' onChange={e => setTitle(e.target.value)} value={title} />
              <label htmlFor='workflowName'>Workflow Name</label>
            </div>
          </Col>
        </Row>
        <Row className='d-flex flex-column justify-content-between mt-4'>
          {steps &&
            steps.map(step => (
              <Col key={step.id} lg={6} className='mx-auto'>
                <div className='d-flex flex-column position-relative mb-5'>
                  <WorkflowStep step={step} updateStep={updateStep} deleteStep={deleteStep} isTrigger={step.type === 'trigger'} className='mb-5' />{' '}
                  {isTriggerConfigured() && (
                    <Button className='mx-auto position-absolute' style={{ bottom: '-10px', left: '42%' }} onClick={() => addStep()}>
                      {' '}
                      <i className='bi bi-plus-circle-fill'></i>
                    </Button>
                  )}
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
};

export default WorkflowEditor;
