import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WorkflowEditor from './components/WorkflowEditor';
import DataDisplay from './components/DataDisplay';
import HomePage from './pages/HomePage';
import HomeNav from './components/HomeNav';
import ZapFlow from './components/ZapFlow';
import DynamicDisplay from './components/DynamicDisplay';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <HomeNav />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/workflow-editor' element={<WorkflowEditor />} />
          <Route path='/display-data' element={<DataDisplay />} />
          <Route path='/workflow/:id' element={<WorkflowEditor />} />
          <Route path='/zap-flow' element={<ZapFlow />} />
          <Route path='/dynamic-form' element={<DynamicDisplay />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
