// frontend/src/components/Zap.js
import React, { useState } from 'react';
import { Accordion, Form, Button } from 'react-bootstrap';


// Mock data for the apps and accounts, updated with real-world examples
const apps = [
  {
    id: 'glip',
    name: 'Glip',
    events: {
      'Create Post': {
        form: [
          {
            type: 'text',
            label: 'Activity Line',
            placeholder: 'new email from {{email.sender}}',
          },
          {
            type: 'text',
            label: 'Subject',
            placeholder: 'Enter the subject...',
          },
          {
            type: 'textarea',
            label: 'Body',
            placeholder: 'Enter the body...',
          },
          {
            type: 'url',
            label: 'Icon',
            placeholder: 'Enter the icon URL...',
          },
        ],
      },
    },
  },
  {
    id: 'ringcentral',
    name: 'RingCentral',
    events: ['Call Ended', 'New Call Recording', 'New SMS/MMS Sent', 'Create Video Meeting', 'Send Fax', 'Custom Actions (Beta)', 'Missed Call', 'New SMS/MMS Received', 'New Voicemail', 'Generate Ringout Call', 'Send SMS/MMS', 'API Request (Beta)'],
  },
  {
    id: 'cognito_forms',
    name: 'Cognito Forms',
    events: ['Entry Deleted', 'New Order', 'New Refund', 'Create Entry', 'Update Entry', 'Custom Actions (Beta)', 'Get Document', 'New Entry', 'New Payment', 'Update Entry', 'Delete Entry', 'Set Form Availability', 'API Request (Beta)', 'Get Entry'],
  },
  {
    id: 'google_sheets',
    name: 'Google Sheets',
    events: ['New Spreadsheet Row (Team Drive)', 'New Spreadsheet', 'New or Updated Spreadsheet Row', 'Create Spreadsheet Column', 'Create Multiple Spreadsheet Rows', 'Create Spreadsheet', 'Delete Spreadsheet Row', 'Update Spreadsheet Row', 'Custom Actions (Beta)', 'Lookup Spreadsheet Rows (output as Line Items)', 'Get Many Spreadsheet Rows (Advanced, output as Line Items)', 'Find or Create Worksheet', 'New Spreadsheet Row', 'New or Updated Spreadsheet Row (Team Drive)', 'New Worksheet', 'Create Spreadsheet Row', 'Copy Worksheet', 'Create Worksheet', 'Format Spreadsheet Row', 'Update Spreadsheet Row(s)', 'API Request (Beta)', 'Find Worksheet', 'Lookup Spreadsheet Row', 'Find or Create Row'],
  },
];

const accounts = {
  ringcentral: ['user1@ringcentral.com', 'user2@ringcentral.com'],
  google_sheets: ['sheetuser1@gmail.com', 'sheetuser2@gmail.com'],
  glip: ['team1@glip.com', 'team2@glip.com'],
  cognito_forms: ['project1@clickup.com', 'project2@clickup.com'],
};

function Zap() {
  const [selectedApp, setSelectedApp] = useState(null); // Initial state is null
  const [formFields, setFormFields] = useState({});
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [formData, setFormData] = useState('');
  const [activeKey, setActiveKey] = useState('0');


  const handleContinue = nextKey => {
    setActiveKey(nextKey);
  };

  const handleAppSelect = app => {
    setSelectedApp(app); // Store the entire app object
    setSelectedEvent('');
    setFormFields({}); // Reset the form fields
    // Also make sure to set the formData to empty or the initial state if needed
    setFormData('');
  };

  const toggleAccordion = key => {
    // If the current key is already active, set it to null (collapse the panel)
    // Otherwise, set the active key to the selected key (expand the panel)
    setActiveKey(activeKey === key ? null : key);
  };

  const renderAppDropdown = () => {
    return (
      <select className='form-control mt-3' value={selectedApp ? selectedApp.name : ''} onChange={(e) => handleAppSelect(apps.find(app => app.name === e.target.value))}>
        <option value="">Select App</option>
        {apps.map(app => (
          <option key={app.id} value={app.name}>
            {app.name}
          </option>
        ))}
      </select>
    );
  };


const renderEventDropdown = () => {
  if (!selectedApp) {
    return null;
  }

  // selectedApp should be an object, so we don't need to find it again
  const eventNames = Object.keys(selectedApp.events);

  return (
    <select className='form-control mt-3' value={selectedEvent} onChange={(e) => setSelectedEvent(e.target.value)} disabled={!selectedApp}>
      <option value="">Select Event</option>
      {eventNames.map(eventName => (
        <option key={eventName} value={eventName}>
          {eventName}
        </option>
      ))}
    </select>
  );
};

const renderAccountDropdown = () => {
  // Ensure selectedApp is not null before trying to access its properties
  if (!selectedApp) {
    return null;
  }

  // Now we can safely access selectedApp.id because we have checked selectedApp is not null
  const accountList = accounts[selectedApp.id] || [];

  return (
    <Form.Control as="select" variant="success" id="dropdown-accounts" value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
      <option value="">Select Account</option>
      {accountList.map((account, index) => (
        <option key={index} value={account}>
          {account}
        </option>
      ))}
    </Form.Control>
  );
};

  const renderForm = () => {
    if (!selectedApp || !selectedEvent) {
      return null;
    }

    // Find the selected app object using the selectedApp ID
      const eventForm = selectedApp && selectedApp.events[selectedEvent]?.form;


    if (!eventForm) {
      return null;
    }
    return (
      <Form>
        {eventForm.map((inputField, index) => (
          <Form.Group key={index}>
            <Form.Label>{inputField.label}</Form.Label>
            {inputField.type === 'textarea' ? (
              <Form.Control
                as='textarea'
                placeholder={inputField.placeholder}
                value={formFields[inputField.label] || ''}
                onChange={e =>
                  setFormFields({
                    ...formFields,
                    [inputField.label]: e.target.value,
                  })
                }
              />
            ) : (
              <Form.Control
                type={inputField.type}
                placeholder={inputField.placeholder}
                value={formFields[inputField.label] || ''}
                onChange={e =>
                  setFormFields({
                    ...formFields,
                    [inputField.label]: e.target.value,
                  })
                }
              />
            )}
          </Form.Group>
        ))}
        {/* Continue button here */}
      </Form>
    );
  };

  return (
    <Accordion activeKey={activeKey}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header onClick={() => toggleAccordion('0')}>App/Event Selection</Accordion.Header>
        <Accordion.Body>
          {renderAppDropdown()}
          {renderEventDropdown()}
          <Button variant='primary' className='mt-3' onClick={() => handleContinue('1')} disabled={!selectedEvent}>
            Continue
          </Button>
        </Accordion.Body>
      </Accordion.Item>

      {/* Authentication */}
      <Accordion.Item eventKey='1'>
        <Accordion.Header onClick={() => toggleAccordion('1')}>Authentication</Accordion.Header>
        <Accordion.Body>
          {renderAccountDropdown()}
          <Button variant='primary' className='mt-3' onClick={() => handleContinue('2')} disabled={!selectedAccount}>
            Continue
          </Button>
        </Accordion.Body>
      </Accordion.Item>

      {/* Data Input Fields/Form */}
      <Accordion.Item eventKey='2'>
        <Accordion.Header onClick={() => toggleAccordion('2')}>Data Input Fields / Form</Accordion.Header>
        <Accordion.Body>
          {renderForm()}
          <Button variant='primary' className='mt-3' onClick={() => handleContinue('3')} disabled={!formData}>
            Continue
          </Button>
        </Accordion.Body>
      </Accordion.Item>

      {/* Test and Continue */}
      <Accordion.Item eventKey='3'>
        <Accordion.Header onClick={() => toggleAccordion('3')}>Test and Continue</Accordion.Header>
        <Accordion.Body>
          <p>This section is for testing the setup and continuing to the next step or finishing the configuration.</p>
          {/* Implement test logic */}
          <Button variant='primary' className='mt-3' onClick={() => handleContinue('4')}>
            Skip Test
          </Button>
          <Button variant='primary' className='mt-3' onClick={() => handleContinue('5')}>
            Test Step
          </Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Zap;
