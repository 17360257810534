import React, { useState, useEffect } from 'react';
import { Form, Card, Popover, Button, OverlayTrigger } from 'react-bootstrap';
import TriggerTypeSelection from './TriggerTypeSelection';
import { getTriggers } from '../util';


const WorkflowStep = ({ step, updateStep, deleteStep, isTrigger }) => {
  const [note, setNote] = useState(step.note || '');

  const [availableStepTypes, setAvailableStepTypes] = useState([]);
  const selectedType = step.type;
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [triggers, setTriggers] = useState([]);

  useEffect(() => {
    getTriggers().then(data => {
      if(data) setTriggers(data)
    })
  }, [])

  const handleChange = e => {
    const { name, value } = e.target;
    updateStep(step.id, { ...step, [name]: value });
  };

  const onTriggerSelected = trigger => {
    updateStep(step.id, { ...step, triggerType: trigger });
  };

  const onTypeSelected = type => {
    updateStep(step.id, { ...step, triggerTypeName: type });
  };

  const actionAndBothTypes = Object.entries(triggers).flatMap(([key, value]) =>
    Object.entries(value.types)
      .filter(([typeKey, typeValue]) => typeValue.role === 'action' || typeValue.role === 'both')
      .map(([typeKey, typeValue]) => ({
        trigger: key,
        type: typeKey,
        label: `${value.label} (${typeKey})`,
      }))
  );
console.log('actionandbothtypes',actionAndBothTypes)
  const handleNoteSave = () => {
    updateStep(step.id, { ...step, note });
  };

  // Define the popover content for note taking
  const notePopover = (
    <Popover id='popover-basic'>
      <Popover.Header as='h3'>Leave a Note</Popover.Header>
      <Popover.Body>
        <Form.Control as='textarea' rows='3' value={note} onChange={e => setNote(e.target.value)} placeholder='Type your note here...' />
        <Button variant='primary' size='sm' onClick={handleNoteSave} className='mt-2'>
          Save
        </Button>
      </Popover.Body>
    </Popover>
  );

  // Use OverlayTrigger to attach the popover to the sticky icon
  const renderNoteIcon = () => (
    <OverlayTrigger trigger='click' placement='right' overlay={notePopover} rootClose>
      <i className='bi bi-sticky fs-4' style={{ cursor: 'pointer', color: '#ff5400' }}></i>
    </OverlayTrigger>
  );

  useEffect(() => {
    if (step.name) {
      const stepTypes = Object.entries(triggers[step.name]?.types || {})
        .filter(([typeKey, typeValue]) => typeValue.role === 'action' || typeValue.role === 'both')
        .map(([typeKey, typeValue]) => typeKey);
      setAvailableStepTypes(stepTypes);
    }
  }, [step.name]);

  useEffect(() => {
    if (step.triggerTypeName && triggers[step.triggerType]?.types[step.triggerTypeName]?.scenario) {
      const scenarios = triggers[step.triggerType].types[step.triggerTypeName].scenario.stage;
      setScenarioOptions(scenarios || []);
    } else {
      setScenarioOptions([]);
    }
  }, [step.triggerType, step.triggerTypeName]);

  useEffect(() => {
    setNote(step.note || '');
  }, [step.note]);

  const handleStepNameChange = e => {
    const newStepName = e.target.value;

    updateStep(step.id, { ...step, name: newStepName, type: '' });
  };

  const handleStepTypeChange = e => {
    const newStepType = e.target.value;
    updateStep(step.id, { ...step, type: newStepType });
  };

  const renderScenarioOptions = () => {
    return (
      scenarioOptions.length > 0 && (
        <Form.Group controlId={`formScenario${step.id}`}>
          <Form.Label>Scenario</Form.Label>
          <Form.Control as='select' name='scenario' value={step.scenario || ''} onChange={handleChange}>
            <option value=''>Select a scenario</option>
            {scenarioOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )
    );
  };

  return (
    <Card className='mb-3'>
      <Card.Body>
        <Form>
          {isTrigger && (
            <>
              <div className='d-flex flex-row justify-content-between mb-3 align-items-center'>
                <h5 className='text-capitalize mb-0'>{!step.triggerType && !step.triggerTypeName ? 'Trigger' : `${step.triggerTypeName} ${step.triggerType}`}</h5>
                {renderNoteIcon()}
              </div>
              <TriggerTypeSelection selectedTrigger={step.triggerType} onTriggerSelected={onTriggerSelected} onTypeSelected={onTypeSelected} selectedType={step.triggerTypeName} />
              <Form.Group controlId='formTriggerDescription'>
                <Form.Label>Description</Form.Label>
                <Form.Control as='textarea' name='description' value={step.description || ''} onChange={handleChange} placeholder='Describe the trigger condition' />
              </Form.Group>
            </>
          )}
          {!isTrigger && (
            <>
              <div className='d-flex flex-row justify-content-between mb-3 align-items-center'>
                <h5 className='text-capitalize mb-0'>{!step.triggerType && !step.triggerTypeName ? 'Step Name' : `${step.triggerTypeName} ${step.triggerType}`}</h5>

                <div className='ms-auto me-0'>
                  {renderNoteIcon()}
                  <i className='bi bi-trash text-danger fs-4' onClick={() => deleteStep(step.id)}></i>
                </div>
              </div>
              <Form.Group controlId={`formStepName${step.id}`} className='mb-3'>
                <Form.FloatingLabel label='Action Name'>
                  <Form.Select value={step.name} onChange={handleStepNameChange}>
                    <option value=''>Select an Action</option>
                    {Object.keys(triggers).map(key => (
                      <option key={key} value={key}>
                        {triggers[key].label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.FloatingLabel>
              </Form.Group>
              {step.name && availableStepTypes.length > 0 && (
                <Form.Group controlId={`formStepType${step.id}`} className='mb-3'>
                  <Form.Floating>
                    <Form.Select value={step.type} onChange={handleStepTypeChange}>
                      <option value=''>Select a type</option>
                      {availableStepTypes.map(type => (
                        <option key={type} value={type}>
                          {actionAndBothTypes.find(action => action.trigger === step.name && action.type === type)?.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Label>Step Type</Form.Label>
                  </Form.Floating>
                </Form.Group>
              )}
              {step.name && selectedType && triggers[step.name]?.types[selectedType]?.scenario && renderScenarioOptions()}
              <Form.Group controlId={`formStepDescription${step.id}`} className='mb-3'>
                <Form.Floating>
                  <Form.Control as='textarea' name='description' value={step.description || ''} onChange={handleChange} placeholder='Describe the step' />
                  <Form.Label htmlFor={`formStepDescription${step.id}`}>Description</Form.Label>
                </Form.Floating>
              </Form.Group>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default WorkflowStep;
