import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Card } from "react-bootstrap";
import EmojiPicker from "./EmojiPicker";
import { getTriggers } from "../util";

const DynamicForm = () => {
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({ label: "", type: "", role: "" });

  const [showAddModal, setShowAddModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    getTriggers().then((triggers) => {
      if(triggers){
        const initialRows = Object.entries(triggers).flatMap(([key, value]) =>
          Object.entries(value.types).map(([typeKey, typeValue]) => ({
            id: `${key}-${typeKey}`,
            label: value.label,
            type: typeKey,
            role: typeValue.role,
          }))
        );
        setRows(initialRows);
      }
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRow({ ...newRow, [name]: value });
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    addTrigger(newRow);
    resetForm();
    setShowAddModal(false);
  };

  const resetForm = () => {
    setNewRow({ label: "", type: "", role: "" });
    setEditIndex(-1); // Reset edit index
  };

  // Function to add a new trigger
  const addTrigger = (newData) => {
    fetch("/saveTriggers.php", {
      // Assuming saveTriggers.php is at the web root
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // Handle success
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Card style={{ marginTop: "7rem" }} className="mx-auto w-75">
      <Card.Header>Dynamic Form</Card.Header>
      <Card.Body>
        <Button
          variant="primary"
          onClick={() => setShowAddModal(true)}
          className="mb-3"
        >
          Add New
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Action</th>
              <th>Event</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.label}</td>
                <td>{row.type}</td>
                <td>{row.role}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* Modal for Adding a New Row */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Row</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleAddRow}>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label>Action</Form.Label>
                <EmojiPicker
                  value={newRow.label}
                  onChange={(value) => setNewRow({ ...newRow, label: value })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  name="type"
                  required
                  value={newRow.type}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  name="role"
                  required
                  value={newRow.role}
                  onChange={handleInputChange}
                >
                  <option value="">Select Role</option>
                  <option value="trigger">Trigger</option>
                  <option value="action">Action</option>
                  <option value="both">Both</option>
                </Form.Control>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAddModal(false)}
              >
                Close
              </Button>
              <Button type="submit" variant="primary">
                Add
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default DynamicForm;
