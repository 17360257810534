export const getTriggers = async () => {
    const url = "/data/triggers.json";
    try {
        // Make a fetch request to the provided URL
        const response = await fetch(url);
    
        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        // Parse the JSON data from the response
        const data = await response.json();
    
        // Return the JSON data
        return data;
      } catch (error) {
        // Log any errors to the console
        console.error("Error fetching data: ", error);
        // You might want to handle the error differently,
        // e.g., by returning a default value or re-throwing the error
        return null; // or throw error;
      }
}