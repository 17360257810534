import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { getTriggers } from '../util';

function TriggerTypeSelection({ onTriggerSelected, onTypeSelected, selectedTrigger, selectedType }) {
  const [availableTypes, setAvailableTypes] = useState([]);
  const [triggers, setTriggers] = useState([]);

  useEffect(() => {
    getTriggers().then(data => {
      if(data) setTriggers(data)
    })
  }, [])

  useEffect(() => {
    if (selectedTrigger && triggers[selectedTrigger]?.types) {
      const types = Object.entries(triggers[selectedTrigger].types)
        .filter(([_, type]) => type.role === 'trigger')
        .map(([key, value]) => ({
          value: key,
          label: value.label || key.charAt(0).toUpperCase() + key.slice(1), // Use label if available, else capitalize the key
          role: value.role,
        }));
      setAvailableTypes(types);
    } else {
      setAvailableTypes([]);
    }
  }, [selectedTrigger]);

  useEffect(() => {
    if (selectedType) {
      const scenarioOptions = triggers[selectedTrigger]?.types[selectedType]?.scenario;
      if (scenarioOptions) {
      }
    }
  }, [selectedType, selectedTrigger]);

  const handleTriggerChange = event => {
    const newTrigger = event.target.value;

    onTriggerSelected(newTrigger);

  };

  const handleTypeChange = event => {
    const newType = event.target.value;

    onTypeSelected(newType);
  };

  console.log('Rendering TriggerTypeSelection');

  return (
    <>
      <Form.Group className='form-floating mb-3' controlId='triggerSelect'>
        <Form.Control as='select' value={selectedTrigger} onChange={handleTriggerChange} className='form-select'>
          <option value=''>Select a trigger</option>
          {Object.keys(triggers).map(key => (
            <option key={key} value={key}>
              {triggers[key].label}
            </option>
          ))}
        </Form.Control>
        <Form.Label>Trigger</Form.Label>
      </Form.Group>

      {selectedTrigger && availableTypes.length > 0 && (
        <Form.Group className='form-floating mb-3' controlId='typeSelect'>
          <Form.Control as='select' value={selectedType} onChange={handleTypeChange} className='form-select'>
            <option value=''>Select a type</option>
            {availableTypes
              .filter(typeObj => typeObj.role === 'trigger')
              .map(typeObj => (
                <option key={typeObj.value} value={typeObj.value}>
                  {typeObj.label}
                </option>
              ))}
          </Form.Control>
          <Form.Label>Type</Form.Label>
        </Form.Group>
      )}
    </>
  );
}

export default TriggerTypeSelection;
