import React, { useState, useEffect } from 'react';
import { CardList, Grid3x3GapFill, Trash } from 'react-bootstrap-icons'; // Import the Trash icon
import { Card, Container, Row, Col, Table, Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

function DataDisplay() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [viewMode, setViewMode] = useState('cards');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  function sortData(data) {
    if (!sortConfig.key) return data; // No sorting if no key is set
    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/getFlow.php');
        const fetchedData = await response.json();
        if (response.ok) {
          setData(fetchedData);
        } else {
          setError('Failed to fetch data');
        }
      } catch (error) {
        setError(`Error fetching data: ${error.message}`);
      }
    };
    fetchData();
  }, []);

  const filteredData = data.filter(item => item.title.toLowerCase().includes(filter) || item.steps.some(step => step.type.toLowerCase().includes(filter) || step.triggerType.toLowerCase().includes(filter)));

  const sortedData = sortData(filteredData);

  function formatDate(dateString) {
    if (!dateString) {
      return 'No last updated date';
    }
    return format(parseISO(dateString), 'PPPpp');
  }

  const handleFilterChange = event => {
    setFilter(event.target.value.toLowerCase());
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'cards' ? 'table' : 'cards');
  };

  const handleDelete = id => {
    const updatedData = data.filter(item => item.id !== id);
    setData(updatedData);
    fetch('/deleteFlow.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <Container className='pt-5'>
      <Row className='my-4'>
        <Col className='d-flex flex-row'>
          <input type='text' className='form-control' placeholder='Filter workflows...' value={filter} onChange={handleFilterChange} />
          <ButtonGroup>
            <Button variant={viewMode === 'cards' ? 'primary' : 'secondary'} onClick={() => toggleViewMode('cards')}>
              <Grid3x3GapFill />
            </Button>
            <Button variant={viewMode === 'table' ? 'primary' : 'secondary'} onClick={() => toggleViewMode('table')}>
              <CardList />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {error ? (
        <div className='alert alert-danger'>{error}</div>
      ) : viewMode === 'cards' ? (
        <Row>
          {filteredData.map(item => (
            <Col key={item.id} md={4} className='mb-3'>
              <Card>
                <Card.Header>
                  <Link to={`/workflow/${item.id}`}>{item.title || 'Untitled Workflow'}</Link>
                </Card.Header>
                <Card.Body>
                  <Card.Text>Last Updated: {formatDate(item.updated)}</Card.Text>
                  <Card.Text>Steps: {item.steps.length}</Card.Text>
                  {item.steps.map((step, index) => (
                    <div key={index}>
                      <strong>{step.type || 'Step'}:</strong> {step.description || 'No description'}
                    </div>
                  ))}
                  <Button variant='danger' onClick={() => handleDelete(item.id)}>Delete</Button> {/* Add the delete button */}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th onClick={() => requestSort('title')}>Title</th>
              <th onClick={() => requestSort('updated')}>Last Updated</th>
              <th onClick={() => requestSort('steps')}>Steps</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map(item => (
              <tr key={item.id}>
                <td>{item.title || 'Untitled Workflow'}</td>
                <td>{formatDate(item.updated)}</td>
                <td>{item.steps.length}</td>
                <td>
                  <Link to={`/workflow/${item.id}`}>View Workflow</Link>
                  <Button variant='danger' onClick={() => handleDelete(item.id)}>Delete</Button> {/* Add the delete button */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

export default DataDisplay;