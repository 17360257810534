import React from 'react';
import InputEmoji from 'react-input-emoji';

export default function EmojiPicker({ value, onChange }) {

  function handleOnEnter(text) {
    console.log('enter', text);
  }

  return <InputEmoji value={value} onChange={onChange} cleanOnEnter onEnter={handleOnEnter} placeholder='Type a message' />;
}
